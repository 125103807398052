import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Azienda } from "energix-types/src/Azienda";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { AziendePickerPerCredenziali } from "src/components/common/AziendePickerPerCredenziali";
import AppDialogActions from "src/components/elements/AppDialogActions";
import AppDialogTitle from "src/components/elements/AppDialogTitle";
import AppFormField from "src/components/elements/AppFormField";
import {
  GSECredenzialiCreateDto,
  GSECredenzialiUpdateDto,
  GSELoginTaskCreateDto,
  GSECredenzialiDto,
  GSELoginTaskDto,
} from "src/orval/models";

export type GseCredenzialiDialogProps = {
  listaCredenziali: GSECredenzialiDto[];
  credenziali: GSECredenzialiDto | null;
  open: boolean;
  aziende: Azienda[];
  loginTask: GSELoginTaskDto | null;
  initialAziendaId: number | null;
  openModifica: (credenziali: GSECredenzialiDto) => void;

  onClose?: () => void;
  avviaTestCredenziali: (dto: GSELoginTaskCreateDto) => Promise<void>;
  creaCredenziali: (dto: GSECredenzialiCreateDto) => Promise<GSECredenzialiDto>;
  updateCredenziali: (
    id: number,
    credenziali: GSECredenzialiUpdateDto
  ) => Promise<GSECredenzialiDto>;
  associaAzienda: ((id: number, aziendaId: number) => Promise<void>) | null;
};

export function GseCredenzialiDialog({
  listaCredenziali,
  credenziali: credenzialiInitial,
  open,
  aziende,
  loginTask,
  initialAziendaId,
  onClose,
  openModifica,

  avviaTestCredenziali,
  creaCredenziali,
  updateCredenziali,
  associaAzienda,
}: GseCredenzialiDialogProps) {
  const [state, setState] = useState<
    | "test"
    | "test_loading"
    | "test_credenzialiErrate"
    | "test_credenzialiOk"
    | "rinnovo_loading"
    | "rinnovo_error"
    | "edit"
  >("test");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [aziendeIds, setAziendeIds] = useState<number[]>([]);

  const [credenzialiFresh, setCredenzialiJustCreated] =
    useState<GSECredenzialiDto>();

  const [testLoading, setTestLoading] = useState(false);
  const [createLoading, setUpsertLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [associaLoading, setAssociaLoading] = useState(false);
  const [saveType, setSaveType] = useState<
    undefined | "save" | "save_rinnova"
  >();

  useEffect(() => {
    if (!open) {
      return;
    }

    if (credenzialiInitial) {
      setUsername(credenzialiInitial.username);
      setPassword(credenzialiInitial.password);
      setAziendeIds(
        aziende
          .filter((a) => a.credenzialiGSEId === credenzialiInitial.id)
          .map((a) => a.id)
      );

      if (credenzialiInitial.stato === "valide") {
        setState("edit");
      } else {
        setState("test");
      }

      setCredenzialiJustCreated(undefined);
    } else {
      setUsername("");
      setPassword("");
      setState("test");
      setTestLoading(false);
      setAziendeIds(initialAziendaId ? [initialAziendaId] : []);
      setCredenzialiJustCreated(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    credenzialiInitial,
    initialAziendaId,
    //aziende
  ]);

  const credenziali = credenzialiFresh ?? credenzialiInitial;

  useEffect(() => {
    if (
      state === "test_loading" &&
      typeof loginTask?.usernameEPasswordCorretti === "boolean"
    ) {
      if (loginTask.usernameEPasswordCorretti) {
        setState("test_credenzialiOk");
        toast.success("Le credenziali inserite sono corrette!");
      } else {
        setState("test_credenzialiErrate");
        toast.error("Le credenziali inserite non sono corrette");
      }
      setTestLoading(false);
    } else if (state === "test_loading" && loginTask?.errore) {
      setState("test");
      setTestLoading(false);
      toast.error(loginTask?.errore);
    }
  }, [state, loginTask]);

  const credenzialiEsistenti = useMemo(() => {
    if (credenziali) {
      return null;
    }

    const esistenti = listaCredenziali.find(
      (c) => c.username.trim() === username.trim()
    );
    return esistenti ?? null;
  }, [listaCredenziali, username, credenziali]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <AppDialogTitle>
        {credenziali ? "Modifica" : "Aggiungi"} credenziali GSE
      </AppDialogTitle>
      <DialogContent>
        <Stack>
          <AppFormField
            label="Username"
            disabled={!!credenziali || testLoading}
            value={username}
            onChange={setUsername}
          />

          {credenzialiEsistenti ? (
            <Alert severity="warning" sx={{ my: 1 }}>
              Esistono già delle credenziali con questo indirizzo email
              {initialAziendaId && associaAzienda ? (
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => {
                    setAssociaLoading(true);
                    associaAzienda(credenzialiEsistenti.id, initialAziendaId)
                      .then(() => {
                        toast.success("Credenziali associate con successo!");
                        onClose?.();
                      })
                      .catch((e) => {
                        console.log(e);
                        toast.error(
                          "Errore durante l'associazione' delle credenziali"
                        );
                      })
                      .finally(() => {
                        setAssociaLoading(false);
                      });
                  }}
                  disabled={associaLoading}
                  startIcon={associaLoading && <CircularProgress size={20} />}
                >
                  Associa all'azienda
                </Button>
              ) : (
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => {
                    openModifica(credenzialiEsistenti);
                  }}
                >
                  Modifica esistenti
                </Button>
              )}
            </Alert>
          ) : null}

          <AppFormField
            label="Password"
            value={password}
            disabled={testLoading || !!credenzialiEsistenti}
            onChange={(p) => {
              setPassword(p);
              setState("test");
            }}
          />

          {testLoading ||
          state === "test_loading" ||
          state === "test_credenzialiErrate" ||
          state === "test_credenzialiOk" ? (
            <Stack>
              <Typography>
                Stato credenziali:{" "}
                {testLoading || state === "test_loading"
                  ? "Verifica in corso..."
                  : state === "test_credenzialiErrate"
                  ? "Credenziali errate"
                  : state === "test_credenzialiOk"
                  ? "Credenziali valide"
                  : "Sconosciuto"}
              </Typography>
            </Stack>
          ) : (
            <StatoCredenziali
              credenziali={credenziali}
              listaCredenziali={listaCredenziali}
            />
          )}

          {credenziali && state === "edit" && (
            <AziendePickerPerCredenziali
              aziende={aziende}
              credenziali={credenziali}
              value={aziendeIds}
              onChange={setAziendeIds}
              credenzialiKey="credenzialiGSEId"
            />
          )}
        </Stack>
      </DialogContent>
      <AppDialogActions>
        <Button onClick={() => onClose?.()} variant="outlined">
          Annulla
        </Button>

        {state === "test" ||
        state === "test_loading" ||
        state === "test_credenzialiErrate" ? (
          <>
            <Button
              variant="contained"
              disabled={
                testLoading ||
                username.trim().length <= 0 ||
                password.length <= 0 ||
                !!credenzialiEsistenti
              }
              onClick={async () => {
                setTestLoading(true);
                avviaTestCredenziali({
                  username: username.trim(),
                  password,
                  credenzialiGSEId: credenziali ? credenziali.id : null,
                })
                  .then(() => {
                    setState("test_loading");
                  })
                  .catch(() => {
                    toast.error(
                      "Errore durante la verifica delle credenziali. Riprovare più tardi"
                    );
                    setState("test");
                  });
              }}
              startIcon={
                (testLoading || state === "test_loading") && (
                  <CircularProgress size={20} />
                )
              }
            >
              Verifica credenziali
            </Button>
          </>
        ) : state === "test_credenzialiOk" ||
          state === "rinnovo_loading" ||
          state === "rinnovo_error" ? (
          <>
            <Button
              onClick={() => {
                if (!loginTask) {
                  return;
                }

                setSaveType("save");
                setUpsertLoading(true);

                const promise = credenziali
                  ? updateCredenziali(credenziali.id, {
                      password: password,
                      aziendeIds: aziendeIds,
                      gseLoginTaskDtoId: loginTask ? loginTask.id : null,
                    })
                  : creaCredenziali({
                      gseLoginTaskDtoId: loginTask.id,
                    });
                promise
                  .then((credenziali) => {
                    setCredenzialiJustCreated(credenziali);
                    toast.success("Credenziali salvate!");
                    setState("edit");
                  })
                  .catch((e) => {
                    console.log(e);
                    toast.error(
                      "Errore durante il salvataggio delle credenziali. Riprova più tardi"
                    );
                  })
                  .finally(() => {
                    setUpsertLoading(false);
                  });
              }}
              variant="contained"
              startIcon={
                saveType === "save" &&
                createLoading && <CircularProgress size={20} />
              }
            >
              Salva
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => {
                if (!credenziali) {
                  return;
                }
                setUpdateLoading(true);
                updateCredenziali(credenziali.id, {
                  password: password,
                  aziendeIds: aziendeIds,
                  gseLoginTaskDtoId: loginTask ? loginTask.id : null,
                })
                  .then(() => {
                    toast.success("Credenziali aggiornate con successo!");
                    onClose?.();
                  })
                  .catch(() => {
                    toast.error(
                      "Errore durante l'aggiornamento delle credenziali"
                    );
                  })
                  .finally(() => {
                    setUpdateLoading(false);
                  });
              }}
            >
              Salva
            </Button>
          </>
        )}
      </AppDialogActions>
    </Dialog>
  );
}

export function useGseCredenzialiDialog(
  listaCredenziali: GSECredenzialiDto[],
  aziende: Azienda[],
  loginTask: GSELoginTaskDto | null,
  avviaTestCredenziali: (dto: GSELoginTaskCreateDto) => Promise<void>,
  creaCredenziali: (dto: GSECredenzialiCreateDto) => Promise<GSECredenzialiDto>,
  updateCredenziali: (
    id: number,
    credenziali: GSECredenzialiUpdateDto
  ) => Promise<GSECredenzialiDto>,
  associaAzienda: ((id: number, aziendaId: number) => Promise<void>) | null
) {
  const [credenziali, setCredenzali] = useState<GSECredenzialiDto | null>(null);
  const [open, setOpen] = useState(false);
  const [initialAziendaId, setInitialAziendaId] = useState<number | null>(null);

  const openModifica = useCallback(
    (credenziali: GSECredenzialiDto) => {
      setCredenzali(credenziali);
      setOpen(true);
    },
    [setCredenzali, setOpen]
  );
  const openNew = useCallback(
    (id: number | null) => {
      setInitialAziendaId(id);
      setCredenzali(null);
      setOpen(true);
    },
    [setCredenzali, setOpen]
  );

  return {
    openModifica: openModifica,
    openNew: openNew,
    dialog: (
      <GseCredenzialiDialog
        listaCredenziali={listaCredenziali}
        credenziali={credenziali}
        aziende={aziende}
        open={open}
        loginTask={loginTask}
        avviaTestCredenziali={avviaTestCredenziali}
        creaCredenziali={creaCredenziali}
        updateCredenziali={updateCredenziali}
        onClose={() => {
          setOpen(false);
          setCredenzali(null);
        }}
        initialAziendaId={initialAziendaId}
        openModifica={openModifica}
        associaAzienda={associaAzienda}
      />
    ),
  };
}

function StatoCredenziali({
  credenziali: _credenziali,
  listaCredenziali,
}: {
  credenziali: GSECredenzialiDto | null | undefined;
  listaCredenziali: GSECredenzialiDto[];
}) {
  const credenziali = useMemo(() => {
    if (!_credenziali) {
      return null;
    }

    return listaCredenziali.find((c) => c.id === _credenziali.id);
  }, [listaCredenziali, _credenziali]);

  const formattedDate = useMemo(() => {
    if (!credenziali?.statoUltimoAggiornamento) {
      return "";
    }

    const d = moment(credenziali.statoUltimoAggiornamento);
    return `(stato aggiornato il ${d.format("DD/MM/YYYY")} alle ${d.format(
      "HH:mm"
    )})`;
  }, [credenziali]);

  if (!credenziali) {
    return null;
  }

  const stato =
    credenziali.stato === "valide"
      ? "Credenziali valide"
      : credenziali.stato === "non_valide"
      ? "Credenziali non valide"
      : "Stato sconosciuto";

  return (
    <Stack sx={{ my: 1 }}>
      <Typography>
        Stato credenziali: {stato} {formattedDate}
      </Typography>
    </Stack>
  );
}
