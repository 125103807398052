import { Azienda } from "energix-types/src/Azienda";
import { Autocomplete, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { orderBy } from "lodash";

export type AziendaPickerProps = {
  aziende: Azienda[];
  value: number | null;
  onChange: (aziendaId: number | null) => void;
  disabled?: boolean;
};

export default function AziendaPicker({
  aziende,
  value,
  onChange,
  disabled = false,
}: AziendaPickerProps) {
  const sortedAziende = useMemo(
    () => orderBy(aziende, (a) => a.richiedenteNome),
    [aziende]
  );
  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      options={sortedAziende}
      fullWidth
      getOptionLabel={(a) => a.richiedenteNome ?? ""}
      value={value ? aziende.find((a) => a.id === value) : null}
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, selected) => {
        onChange(selected?.id ?? null);
      }}
      size="small"
    />
  );
}
