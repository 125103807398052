import { Azienda } from "energix-types/src/Azienda";
import {
  Control,
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
} from "react-hook-form";
import AziendaPicker from "src/components/elements/AziendaPicker";
import { getErrorText } from "./AppControllerFormField";
import { Typography } from "@mui/material";

export type ControlledAziendaPickerProps<
  T extends FieldValues,
  P extends Path<T>
> = {
  control: Control<T, any>;
  name: P & (FieldPathValue<T, P> extends number | null ? string : never);
  aziende: Azienda[];
  disabled?: boolean;
};

export default function ControlledAziendaPicker<
  T extends FieldValues,
  P extends Path<T>
>({ control, name, aziende, disabled }: ControlledAziendaPickerProps<T, P>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState }) => {
        const errorText = getErrorText(formState, name);
        return (
          <>
            <AziendaPicker
              aziende={aziende}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
            />
            {errorText && (
              <Typography color="error" variant="body2" mt={1}>
                Campo obbligatorio
              </Typography>
            )}
          </>
        );
      }}
    />
  );
}
